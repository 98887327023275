<template>
    <div id="mint">
        <div class="upLoadTitle">Fill Series Info</div>
        <div class="uploadNotes">
            Before distributing NFT works, the cover picture replaces NFT display
        </div>
        <div class="uploadNotesInfo">
            File types supported: JPG, PNG, SVG
            <span>Max size: 100 MB<span class="required">*</span></span>
        </div>
        <div class="uploadImg">
            <el-upload :class="{ hide: hideUpload2 }" ref="my-uploadThree" :action="baseUrl" list-type="picture-card" :file-list="fileList2" :on-change="handleChange2" :before-upload="logoBeforeUpload" :on-remove="handleRemove2" :on-success="logoHandleSuccess2">
                <i class="el-icon-picture"></i>
            </el-upload>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Name<span>*</span></div>
            <div class="form_view_input">
                <input type="text" v-model="minNewHistoryQuery.seriesname" placeholder="Series Name" />
            </div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Description<span>*</span></div>
            <div class="form_view_input">
                <textarea v-model="minNewHistoryQuery.description" placeholder="Provide a detailed description of your item."></textarea>
            </div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Logo Image<span>*</span></div>
            <div class="uploadNotesInfo">
                This image will also be used for navigation. 350 x 350 recommended.
            </div>
            <div class="uploadImgTwo">
                <el-upload :class="{ hide: hideUpload1 }" ref="my-uploadTwo" :action="baseUrl" list-type="picture-card" :file-list="fileList1" :on-change="handleChange1" :before-upload="logoBeforeUpload" :on-remove="handleRemove1" :on-success="logoHandleSuccess1">
                </el-upload>
            </div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Series Banner Image</div>
            <div class="uploadNotesInfo">
                This image will appear at the top of your series page. Avoid including
                too much text in this banner image, as the dimensions <br>
                change on different devices. 1400 x 400 recommended.
            </div>
            <div class="uploadImgThree">
                <el-upload :class="{ hide: hideUpload }" ref="my-upload" :action="baseUrl" list-type="picture-card" :file-list="fileList" :on-change="handleChange" :before-upload="logoBeforeUpload" :on-remove="handleRemove" :on-success="logoHandleSuccess">
                </el-upload>
            </div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Independent Collection</div>
            <div class="uploadNotesInfo2">
                <div class="div1">Do you need to create an independent Collection in Opensea?</div>
                <el-switch class="switch-btn" v-model="value" active-color="#FFE161" inactive-color="#C1CBDB">
                </el-switch>
            </div>
        </div>
        <div class="mintCreate_button">
            <button :disabled="
          operationLoading ||
          uploadImg === '' ||
          uploadImg1 === '' ||
          minNewHistoryQuery.description === '' ||
          minNewHistoryQuery.seriesname === ''
        " :class="{
          mintCreate_button_true:
            uploadImg !== '' &&
            uploadImg1 !== '' &&
            minNewHistoryQuery.description !== '' &&
            minNewHistoryQuery.seriesname !== '',
        }" @click="mintHandle">
                <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                <span v-else>Confirm</span>
            </button>
        </div>
    </div>
</template>
<script>
import { getChainIdNetwork } from "../../web3/common/wallet";
const wallet = require("../../web3/common/wallet.js");
import { createHistoryNewV1ERC721 } from "../../web3/HistorySeriesERC721";
import { createHistoryERC72101S } from "../../web3/HistorySeriesERC72101S";
import { chainName } from "../../web3/web3Config";
import toLowerCaseNumber from "../../plugins/hex2int.js";
const address = require("../../web3/web3Config.js");

export default {
    name: "create",
    data() {
        return {
            // baseUrl: this.$api.post_uploadseriesnftpic,
            baseUrl: "https://aapi.historydao.io/history/uploadseriesnftpic",
            // baseUrl: "https://api.blockhistory.io/history/uploadseriesnftpic",
            // baseUrl: "http://18.222.137.245:9081/history/uploadseriesnftpic", 
            baseUrlData: "",
            file: {},
            fileList: [],
            fileList1: [],
            fileList2: [],
            uploadImg: "",
            uploadImg1: "",
            uploadImg2: "",
            value: true,
            hideUpload: false,
            hideUpload1: false,
            hideUpload2: false,
            operationLoading: false,
            chainName: chainName,
            isClose: "",
            minNewHistoryQuery: {
                admin: "",
                seriesid: "",
                seriesname: "",
                serieslogo: "",
                seriesbanner: "",
                description: "",
                blindboxcover: "",
                tokenaddress: "",
            },
            HistorySeriesERC721: '',
        };
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    mounted() {},
    methods: {

        async init() {
            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }
            this.minNewHistoryQuery.contractMinter = address.address[this.chainName[res]].SeriesMinter01;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
        },


        logoHandleSuccess(response) {
            this.uploadImg = response.result.awsUrl;
            this.minNewHistoryQuery.serieslogo = response.result.hash;
        },
        handleChange(file, fileList) {
            fileList.length >= 1 ?
                (this.hideUpload = true) :
                (this.hideUpload = false);
        },
        handleRemove(file, fileList) {
            fileList.length >= 1 ?
                (this.hideUpload = true) :
                (this.hideUpload = false);
            this.uploadImg = "";
            this.minNewHistoryQuery.serieslogo = "";
        },
        logoBeforeUpload(file) {
            this.file = file;
            const isLt2M = file.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                this.$message.error(
                    "The size of the uploaded picture cannot exceed 100MB!"
                );
            }
            return isLt2M;
        },
        logoHandleSuccess1(response) {
            this.uploadImg1 = response.result.awsUrl;
            this.minNewHistoryQuery.seriesbanner = response.result.hash;
        },
        handleChange1(file, fileList1) {
            fileList1.length >= 1 ?
                (this.hideUpload1 = true) :
                (this.hideUpload1 = false);
        },
        handleRemove1(file, fileList1) {
            fileList1.length >= 1 ?
                (this.hideUpload1 = true) :
                (this.hideUpload1 = false);
            this.uploadImg1 = "";
            this.minNewHistoryQuery.seriesbanner = "";
        },
        logoHandleSuccess2(response) {
            this.uploadImg2 = response.result.awsUrl;
            this.minNewHistoryQuery.blindboxcover = response.result.hash;
        },
        handleChange2(file, fileList2) {
            fileList2.length >= 1 ?
                (this.hideUpload2 = true) :
                (this.hideUpload2 = false);
        },
        handleRemove2(file, fileList2) {
            fileList2.length >= 1 ?
                (this.hideUpload2 = true) :
                (this.hideUpload2 = false);
            this.uploadImg2 = "";
            this.minNewHistoryQuery.blindboxcover = "";
        },
        mintHandle() {
            if (
                this.minNewHistoryQuery.description !== "" &&
                this.minNewHistoryQuery.seriesname !== "" &&
                this.uploadImg !== "" &&
                this.uploadImg1 !== ""
            ) {
                this.operationLoading = true;
                this.uploadtextRequire();
            }
        },
        uploadtextRequire() {
            if (this.mapState.chainId) {
                //obtain wallet address
                wallet.getWalletInfo().then((info) => {
                    this.minNewHistoryQuery.admin = info.address.toLowerCase();
                    this.minNewHistoryQuery.network = info.network;
                    this.mintServer();
                });
            }
        },
        mintServer() {
            let _this = this;
            // if Collection in Opensea
            if (_this.value == true) {
                let params = this.minNewHistoryQuery
                let ctr = createHistoryERC72101S();
                ctr.startSeries(this.minNewHistoryQuery.seriesname, this.minNewHistoryQuery.seriesname).then((res) => {
                        let seriesidNumber = res.events[2].data.substring(2, 66)
                        this.minNewHistoryQuery.seriesid = toLowerCaseNumber(seriesidNumber);
                        let tokenAddress = '0x' + res.events[2].data.substring(90)
                        let id = JSON.stringify(this.minNewHistoryQuery.seriesid);
                        if (res) {
                            params.tokenaddress = tokenAddress
                            this.$axios.post(this.baseUrlData + this.$api.post_createserires, params).then(() => {
                                    let isShow = true;
                                    let edit = true;
                                    setTimeout(() => {
                                        this.$router.push({
                                            path: "/series",
                                            query: { id, isShow, edit, tokenAddress },
                                        });
                                    }, 1500);
                                    this.$message({
                                        message: "Operation succeeded",
                                        type: "success",
                                    });
                                    _this.clearForm();
                                })
                                .catch(() => {
                                    _this.clearForm();
                                });
                        }
                    })
                    .catch(() => {
                        _this.clearForm();
                        this.fileList = [];
                        this.fileList1 = [];
                        this.fileList2 = [];
                        this.hideUpload = false;
                        this.hideUpload1 = false;
                        this.hideUpload2 = false;
                    });
            } else if (_this.value == false) {
                let params = this.minNewHistoryQuery
                let ctr = createHistoryNewV1ERC721();
                ctr.startSeries(_this.minNewHistoryQuery.contractMinter).then((res) => {
                        let seriesidNumber = res.events[0].data.substring(2);
                        this.minNewHistoryQuery.seriesid = toLowerCaseNumber(seriesidNumber);
                        let id = JSON.stringify(this.minNewHistoryQuery.seriesid);
                        if (res) {
                            params.tokenaddress = this.HistorySeriesERC721
                            this.$axios.post(this.baseUrlData + this.$api.post_createserires, params).then(() => {
                                    let isShow = true;
                                    let edit = true;
                                    let tokenAddress = this.HistorySeriesERC721
                                    setTimeout(() => {
                                        this.$router.push({
                                            path: "/series",
                                            query: { id, isShow, edit, tokenAddress },
                                        });
                                    }, 1500);
                                    this.$message({
                                        message: "Operation succeeded",
                                        type: "success",
                                    });
                                    _this.clearForm();
                                })
                                .catch(() => {
                                    _this.clearForm();
                                });
                        }
                    })
                    .catch(() => {
                        _this.clearForm();
                        this.fileList = [];
                        this.fileList1 = [];
                        this.fileList2 = [];
                        this.hideUpload = false;
                        this.hideUpload1 = false;
                        this.hideUpload2 = false;
                    });
            }
        },
        clearForm() {
            this.operationLoading = false;
            this.minNewHistoryQuery = {
                admin: "",
                seriesid: "",
                seriesname: "",
                serieslogo: "",
                seriesbanner: "",
                description: "",
                blindboxcover: "",
                tokenAddress: "",
            };
            this.uploadImg = "";
            this.uploadImg1 = "";
            this.uploadImg2 = "";
            // this.fileList = [];
            // this.fileList1 = [];
            // this.fileList2 = [];
            // this.hideUpload = false;
            // this.hideUpload1 = false;
            // this.hideUpload2 = false;
        },
    },
    created() {
        this.baseUrlData = localStorage.getItem("baseUrl");
    },
};
</script>
<style lang="scss" src="../../assets/css/mintPage.scss" scoped></style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
    #mint {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;

        .upLoadTitle {
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 500;
            font-size: .8192rem;
            line-height: 1.2288rem;
            /* or 150% */
            color: #353840;
            margin-bottom: .8192rem;
        }

        .uploadNotes {
            font-style: normal;
            font-weight: 500;
            font-size: .8192rem;
            line-height: 1.2288rem;
            /* or 150% */
            color: #353840;
            margin: 0 0 .6144rem 0;

        }

        .uploadNotesInfo {
            margin: 0;
            /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 400;
            font-size: .7168rem;
            line-height: 1.2288rem;
            /* or 171% */
            color: #707A83;

            &>span {
                display: block;
            }
        }

        .uploadImg {
            margin: .6144rem 0 0;
            width: 17.66405rem;
            height: 12.80003rem;
        }

        ::v-deep.form_view {
            margin-top: .8192rem;

            .form_view_title {
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 500;
                font-size: .8192rem;
                line-height: 1.2288rem;
                /* identical to box height, or 150% */
                color: #353840;
            }

            .form_view_input {
                margin-top: .6144rem;

                input {
                    width: 17.66405rem;
                    height: 2.56001rem;
                    border-radius: .512rem;
                    padding: 0 .6144rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: .7168rem;
                    line-height: 1.0752rem;
                    /* identical to box height */
                    color: #8A939B;

                }

                textarea {
                    width: 17.66405rem;
                    height: 9.72802rem;
                    border-radius: .512rem;
                    padding: .768rem .6144rem;
                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 400;
                    font-size: .7168rem;
                    line-height: 1.0752rem;
                    /* identical to box height */
                    color: #8A939B;

                }
            }

            .uploadNotesInfo {
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 400;
                font-size: .7168rem;
                line-height: 1.3312rem;
                /* or 186% */
                color: #707A83;
                margin-top: .6144rem;

                br {
                    display: none;
                }

            }

            .uploadImgTwo {
                margin-top: .6144rem;

                .el-upload--picture-card {
                    width: 7.68002rem;
                    height: 7.68002rem;
                }
            }





            .uploadImgThree {
                margin-top: .6144rem;

                .el-upload--picture-card {
                    width: 17.66405rem;
                    height: 12.80003rem;
                    border-radius: .8192rem;
                    border: .1024rem dashed #CCCCCC;

                    background: url(../../assets/imgs/uploadmint/1.svg) no-repeat center center;
                    background-size: 2.86721rem 2.86721rem;
                }
            }

            .uploadNotesInfo2 {
                margin-top: .6144rem;
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 400;
                font-size: .7168rem;
                line-height: 1.3312rem;
                /* or 186% */
                color: #707A83;

                .div1 {
                    // width: 247px;
                }

            }

            .switch-btn {
                margin-right: 0;
            }


        }

        .mintCreate_button {
            margin-top: .6144rem;

            button {
                width: 17.66405rem;
                height: 2.56001rem;
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 500;
                font-size: .7168rem;
                line-height: 1.0752rem;
                /* identical to box height */
                text-align: center;


            }

            .loading_ani {}
        }
    }

    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
}
</style>