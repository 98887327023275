import ABI_HISTORY_V1_ERC721 from "./abis/minter01s.json"
import IERC721 from "./common/IERC721.js"
import * as wallet from "./common/wallet.js"
import * as web3util from "./common/web3util.js"

const errorPrefix = 'SeriesMinter01S | ';

class SeriesMinter01S extends IERC721 {
    //
    async mintNewHistoryReport(contentHash, name, options={}){
        // Prepare input params
        let _domainId = options.domainId==undefined ? 0 : options.domainId;
        let _usingSSL = options.usingSSL==undefined ? true : options.usingSSL;
        let _value = options.value;
        if(_value == undefined){
            let conf = await this.getConfig();
            _value = conf._mintFee;
        }

        // Write to contract
        const res = await this.write(
            'mintNewHistoryReport',
            [_domainId, _usingSSL, name, contentHash],
            {
                value: _value,
                gasLimit: 360000
            }
        )

        if(res && res.events[0].args[2]){
            return res.events[0].args[2];
        }

        // Process event
        // const event = web3util.findEventArgs(res.events,"Transfer");
        // console.log("Call success with interested event: ", event);
        // if(event && event.tokenId){
        //     return event.tokenId;
        // }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }
    async getConfig() {
        const conf = await this.read('getConfig')
        return conf;
    }

    async ownerOf(tokenId){
        const conf = await this.read('ownerOf',[tokenId])
        return conf;
    }

    async tokenEventId(tokenId){
        const conf = await this.read('tokenEventId',[tokenId])
        return conf;
    }

    async eventData(eventId){
        const conf = await this.read('eventData',[eventId])
        return conf;
    }
    async saleInfo(saleId){
        const conf = await this.read('saleInfo',[saleId])
        return conf;
    }
    async balanceOf(owner){
        const conf = await this.read('balanceOf',[owner])
        return conf;
    }
    async tokenOfOwnerByIndex(owner,index){
        const conf = await this.read('tokenOfOwnerByIndex',[owner,index])
        return conf;
    }
    async numCanMint(saleId,address){
        const conf = await this.read('numCanMint',[saleId,address])
        return conf
    }
    async numSold(saleId){
        const conf = await this.read('numSold',[saleId])
        return conf
    }
    
    async startSeries(name,symbol){
        const res = await this.write(
            'startSeries',
            [name,symbol],
            {
                gasLimit: 25000000
            }
        )
        return res
    }
    async createSale(seriesId,maxPerUser,totalSupply,price,startTime,endTime){
        const res = await this.write(
            'createSale',
            [seriesId,maxPerUser,totalSupply,price,startTime,endTime],
            {
                gasLimit: 360000
            } 
        )
        return res
    }

    async mintSeriesToken(saleId, _count, address, _value){
        const res = await this.write(
            'mintSeriesToken',
            [saleId,_count,address],
            {
                gasLimit: 350000 * _count,
                value: _value
            }
        )
        return res
    }
    async closeMint(signature,seriesId){
        const res = await this.write(
            'closeMint',
            [signature,seriesId],
            {
                gasLimit: 360000,
            }
        )
        return res
    }
    async revealAndWithdraw(signature,seriesId,seriesBaseURI){
        const res = await this.write(
            'revealAndWithdraw',
            [signature,seriesId,seriesBaseURI],
            {
                gasLimit: 360000,
            }
        )
        return res
    }
    
    async followHistoryEvent(eventId, options={}){
        let _value = options.value;
        if(_value == undefined){
            let conf = await this.getConfig();
            _value = conf._mintFee;
        }
        // Write to contract
        const res = await this.write(
            'followHistoryEvent',
            [eventId],
            {
                value: _value,
                gasLimit: 360000
            }
        )

        // Process event
        const event = web3util.findEventArgs(res.events,"Transfer");
        console.log("Call success with interested event: ", event);
        if(event && event.tokenId){
            return event.tokenId;
        }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }

}

export default SeriesMinter01S

export function createHistoryERC72101S() {
    const chainId = wallet.getChainId();
    const adr = web3util.getContractAdr('SeriesMinter01S', chainId)
    return new SeriesMinter01S(ABI_HISTORY_V1_ERC721, adr);
}
